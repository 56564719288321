import React from "react"
import LayoutPage from "../components/layout-page"
import SEO from "../components/seo"
import ImageMeta from "../components/ImageMeta"

const TermsOfServicePage = () => (
  <LayoutPage>
    <SEO title={"Terms of Service"} meta={ImageMeta} />
    <section className="terms bg-light page-padding pb-90">
      <div className="container px-xl-0">
        <div className="row justify-content-center">
          <div className="col-xl-8 col-lg-10 text-center pb-25">
            <h2 className="small" data-aos-duration="600" data-aos="fade-down" data-aos-delay="0">
              Terms of Service
            </h2>
          </div>
        </div>
        <div class="markdownPage">
          <h5 id="thanks-for-using-mixanalogcom-web-services">Thanks for using mixanalog.com web services.</h5>
          <p>
            These terms of service (“Agreement”) cover your use and access to the services, client software and websites (“Services”) provided by Distopik d.o.o.. Our Privacy
            Policy explains how we collect and use your information while our Acceptable Use and Fair Usage Policy outlines our responsibilities when using our Services.
          </p>

          <p>
            By using our Services, you’re agreeing to be bound by the terms of this Agreement, and to review our Privacy and Acceptable Use and Fair Usage Policy. If you’re using
            our Services for an organization, you’re agreeing to the terms of this Agreement on behalf of that organization.
          </p>

          <p>
            By using or accessing the Services, you agree to all the terms and conditions stated in this Agreement. If, at any time, you do not or cannot agree to the terms of this
            Agreement, you must stop accessing or using the Services.
          </p>

          <h5 id="your-assets--your-permissions">Your Assets &amp; Your Permissions</h5>
          <p>
            When you use our Services, you provide us with things like your files, content, configuration settings, Metadata and so on (“Your Assets”). Your Assets are yours. The
            terms of this Agreement don’t give us any rights to Your Assets except for the limited rights that enable us to offer the Services.
          </p>

          <p>
            We need your permission to do things like hosting Your Assets, backing them up, and sharing them when you ask us to. Our Services also provide you with different
            features that may require our systems to access, store and scan Your Assets. We may also utilize Your Data (as defined in our Privacy Policy) to conduct investigations
            and studies, test functionalities during the development phase and analyze the information at our disposal to assess and improve our Services, implement new services
            and functionalities and perform audits and troubleshooting procedures, or for promotional purposes. You give us permission to do these things, and this permission
            extends to trusted third parties we work with.
          </p>

          <p>
            Once you access our Services via an offer or link provided by a MIX:ANALOG partner, it is likely that we will share some of Your Data with said partner, more
            specifically, your name, email address, subscription plan to our Services and information regarding the usage that you make of our Services. You authorize us to share
            such information with the aforementioned partner. The Data shared with this partner will also be governed by conditions of use and this partner’s privacy policy.
          </p>

          <h5 id="pricing">Pricing</h5>
          <p>
            We work for mastering, mixing and recording engineers. Our pricing aims to be affordable to allow as many engineers as possible, from all over the world, to use our
            Services. You can increase your privileges and add paid features to your account (upgrading your account with a Basic, Pro or Master Plan). Please refer to MIX:ANALOG’s
            website for all details regarding our free trial option and subscription plans for our Services and for further details relating to upgrades, downgrades, billing,
            refunds, and changes to Paid accounts. Both free and paying users must have a MIX:ANALOG account to be able to use our Services.
          </p>

          <h5 id="confidentiality">Confidentiality</h5>
          <p>You understand that, while using our Services, you may have access to certain of our confidential information. You agree to keep such information confidential.</p>

          <h5 id="disclaimer-of-warranty-and-limitation-of-liability">Disclaimer of Warranty and Limitation of Liability</h5>
          <p>
            TO THE FULL EXTENT ALLOWED BY APPLICABLE LAW, MIX:ANALOG MAKES NO WARRANTY OR REPRESENTATION REGARDING THE SERVICES, INCLUDING THAT THE SERVICES WILL MEET YOUR
            REQUIREMENTS OR WILL WORK IN COMBINATION WITH ANY HARDWARE, SOFTWARE, CONTENT OR DATA PROVIDED BY THIRD PARTIES, THAT THE SERVICES WILL BE UNINTERRUPTED, WITHOUT
            PROBLEMS OR ERROR FREE, OR THAT ALL ERRORS IN THE SERVICES WILL BE CORRECTED. MIX:ANALOG PROVIDES THE SERVICES “AS IS” AND “AS AVAILABLE”.
          </p>

          <p>
            TO THE FULL EXTENT ALLOWED BY APPLICABLE LAW, MIX:ANALOG’S WARRANTIES AND REMEDIES (IF ANY) EXPRESSLY SET FORTH HEREIN ARE EXCLUSIVE AND ARE IN LIEU OF ALL OTHER
            WARRANTIES, EXPRESS OR IMPLIED, EITHER IN FACT OR BY OPERATION OF LAW, STATUTE, CUSTOM, ORAL OR WRITTEN STATEMENTS OR OTHERWISE, INCLUDING, BUT NOT LIMITED, TO THE
            IMPLIED WARRANTIES OF MERCHANTABILITY, AVAILABILITY, PERFORMANCE, COMPATIBILITY, FITNESS FOR A PARTICULAR PURPOSE, SATISFACTORY QUALITY, CORRESPONDENCE WITH DESCRIPTION
            AND NONINFRINGEMENT, ALL OF WHICH ARE EXPRESSLY DISCLAIMED.
          </p>

          <p>
            TO THE FULL EXTENT ALLOWED BY APPLICABLE LAW, IN NO EVENT SHALL MIX:ANALOG, ITS AFFILIATES, ITS OFFICERS, ITS DIRECTORS, ITS EMPLOYEES, ITS AGENTS, ITS SUPPLIERS, ITS
            LICENSORS AND ITS LICENCEES HAVE ANY LIABILITY, WHETHER BASED IN CONTRACT, DELICT OR TORT (INCLUDING NEGLIGENCE) OR STRICT LIABILITY, FOR INCIDENTAL, INDIRECT,
            CONSEQUENTIAL, SPECIAL, OR PUNITIVE DAMAGES OF ANY KIND, OR FORDIRECT OR INDIRECT) LOSS OF REVENUE OR PROFITS, LOSS OF BUSINESS OR GOODWILL, LOSS OR CORRUPTION OF, OR
            UNAUTHORIZED ACCESS TO, OR DISCLOSURE OF INFORMATION OR DATA OR OTHER FINANCIAL LOSS ARISING OUT OF OR IN CONNECTION WITH THE USE, PERFORMANCE, FAILURE, OR INTERRUPTION
            OF THE SERVICES, WHETHER FORESEEABLE OR NOT, AND EVEN IF MIX:ANALOG HAD BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. IN THE EVENT THAT MIX:ANALOG IS FOUND LIABLE TO
            PAY YOU ANY DAMAGES, MIX:ANALOG’S TOTAL CUMULATIVE LIABILITY TO YOU UNDER THIS AGREEMENT SHALL NOT EXCEED 100 USD. THE ABOVE LIMITATIONS OF LIABILITY WILL NOT BE
            AFFECTED EVEN IF ANY REMEDY PROVIDED HEREIN SHALL FAIL ITS ESSENTIAL PURPOSE.
          </p>

          <h5 id="modifications">Modifications</h5>
          <p>
            We may revise this Agreement from time to time, and will always post the most current version on our website. If a revision meaningfully reduces your rights, we will
            notify you (by, for example, sending a message to the email address associated with your account, posting on our blog or on our website). You agree to review the terms
            of this Agreement from time to time and, in any case, each time we will notify you of changes thereto and by continuing to use or access the Services after the
            revisions come into effect, you will agree to be bound by the revised terms of this Agreement.
          </p>

          <h5 id="general-provisions">General Provisions</h5>
          <p>
            This Agreement shall be governed by and construed by the laws applicable in the Republic of Slovenia. Parties hereby irrevocably submit and attorn to the jurisdiction
            of the District court Ljubljana.
          </p>

          <p>
            This Agreement is the entire and exclusive agreement between MIX:ANALOG and you regarding the Services, and this Agreement supersedes and replaces any prior agreements
            between MIX:ANALOG and you regarding the Services.
          </p>

          <p>
            You shall not assign or otherwise transfer this Agreement or any of its rights or obligations hereunder to any third party without the prior written consent of
            MIX:ANALOG, which consent is within MIX:ANALOG’s sole discretion. No assignment or delegation by you shall relieve or release you from any of your obligations under
            this Agreement. Subject to the foregoing, this Agreement shall be binding upon, inure to the benefit of, and be enforceable by each of the Parties and their respective
            successors and assigns. MIX:ANALOG shall be allowed to assign this Agreement to any third party without requiring your consent, in which case MIX:ANALOG will only be
            released from all its obligations towards you hereunder if the assignee agrees in writing to assume and be bound by such obligations.
          </p>

          <p>Nothing in this Agreement shall constitute a partnership or joint venture between you and MIX:ANALOG.</p>

          <p>
            If a particular provision of this Agreement is held to be invalid within a given jurisdiction by a court of competent jurisdiction, the provision shall be deemed
            severed from this Agreement for that jurisdiction and shall not affect the validity of this Agreement as a whole.
          </p>

          <p>The Parties have expressly requested that this Agreement be drawn up in English and that all modifications thereof can be made in this language.</p>

          <h5 id="contact">Contact</h5>
          <p>If you have any customer service inquiries, concerns, questions or complaints regarding this Agreement, please contact Distopik d.o.o. at:</p>

          <p>Distopik d.o.o.<br/>
          Tobačna ulica 5<br/>
          1000 Ljubljana<br/>
          Slovenia</p>

          <p>The current Agreement last update was July 4th 2018.</p>
        </div>
      </div>
    </section>
  </LayoutPage>
)

export default TermsOfServicePage
